.controls-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;
  display: flex;
  margin: 100px 0 25px;
}

.controls {
  display: inline-flex;
  justify-content: space-between;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 4px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.segment label {
  cursor: pointer;
  display: block;
  font-weight: 700;
  padding: 4px;
  position: relative;
  transition: color 0.5s ease;
}

.segment {
  min-width: 80px;
  position: relative;
  text-align: center;
  z-index: 1;
  color: #555;
}

.segment.active label {
  color: #fff;
}

.controls::before {
  content: '';
  background: rgb(55 65 81);
  border-radius: 8px;
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 0;
  z-index: 0;
}

.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}